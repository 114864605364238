import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Humanity/2024/Mad_ads/1.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/2024/Mad_ads/2.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/2024/Mad_ads/3.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/2024/Mad_ads/4.jpg';

// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';
import Sidebar2024 from '../Humanity2023/Sidebar2024';


const MadAds2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/Humanity/Mad_ads/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/Humanity/Mad_ads/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/Humanity/Mad_ads/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/Humanity/Mad_ads/4.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
      
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h5' align='center' >
      MAD Ads-Éclat  
                                </Typography>
                                <br></br>
                                {/* <Typography variant='h7' align='justify' >
                                Class 4: Diversity Montage
                                Class 5: Continental Montage 

                                </Typography> */}
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 6 & 7  &  Date: 26 July 2024
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Advertising is the art of persuasion”-William Bernbach

<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Humanities Association of National Public School, Yeshwanthpur organized the activity Mad Ads-Éclat on 26th July 2024 for the students of classes 6 and 7. Students learnt to brainstorm and develop creative concepts, honing their ability to craft engaging and persuasive messages. They learnt to craft compelling advertisements by targeting the market trends.  
        <br></br>
        The activity aimed to raise awareness among children about the significance of teamwork, enhancing their collaboration and communication skills. It equipped students with the ability to analyse markets, interpret data and assess competitors to form strategic decisions. The objective was impressively attained by engaging in these advertising activities and helped students to prepare themselves for successful careers in Marketing, Communications and related fields. 
        <br></br>
        The event also aimed to encourage the students to think outside the box and come up with unique ideas to capture the audience's attention. It enhanced creative thinking and the ability to develop market research skills by understanding different perspectives of consumers.
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Designing a product is designing future”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default MadAds2024;